import './App.css';

import { useEffect } from 'react';

import {Routes, Route, BrowserRouter as Router  } from "react-router-dom";

const Privacy = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace('/privacy.htm');
    }, 8);
    return () => clearTimeout(timer);
  });
  return <></>
}

const Support = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace('/support.htm');
    }, 8);
    return () => clearTimeout(timer);
  });
  return <></>
}

function App() {
  return <Router>
    <Routes>
      <Route path={`/privacy`} element={<Privacy />}/>
      <Route path={`/support`} element={<Support />}/>
    </Routes>
  </Router>
}

export default App;
